export const enum TEST_IDS {
  FOOTER = 'footer',
  FOOTER_LOGO_AND_CONTACTS = 'footer-logo-and-contacts',
  FOOTER_LOGO = 'footer-logo',
  FOOTER_SOCIALS = 'footer-socials',
  FOOTER_LINK_NAME = 'footer-link-name',
  FOOTER_LINK_ITEM = 'footer-link-item',
  FOOTER_BOTTOM = 'footer-bottom',
  FOOTER_BOTTOM_RIGHTS = 'footer-bottom-rights',
  FOOTER_SOCIAL_BUTTON = 'footer-social-button',
  HEADER_FIND_PAGE = 'header-find-page',
  HEADER_MAIN_MENU = 'header-main-menu',
  HEADER_MAIN_MENU_OPENED = 'header-main-menu-opened',
  SEARCH_RESULTS_TITLE = 'search-results-title',
  SEARCH_RESULT_CARD = 'search-result-card',
  SEARCH_RESULT_CARD_AVATAR = 'search-result-card-avatar',
  SEARCH_RESULT_CARD_FULL_NAME = 'search-result-card-full-name',
  SEARCH_RESULT_CARD_KEYWORD = 'search-result-card-keyword',
  SEARCH_RESULT_CARD_BADGE = 'search-result-card-badge',
  SEARCH_RESULT_CARD_LOCATION = 'search-result-card-location',
  SEARCH_RESULT_CARD_TREATMENTS = 'search-result-card-treatments',
  SEARCH_RESULT_CARD_CONTACTS = 'search-result-card-contacts',
  SEARCH_RESULTS_PAGINATION = 'search-results-pagination',
  SEARCH_RESULTS_PAGINATION_BUTTON = 'search-results-pagination-button-',
  MOBILE_RESULTS_AUTOCOMPLETE = 'mobile-results-autocomplete',
  MOBILE_RESULTS_SORT_BY = 'mobile-results-sort-by',
  FILTER_MODAL_ACTIVE_FILTER_MODAL = 'filter-modal-active-filter-modal',
  FILTER_MODAL_ACTIVE_FILTER_MODAL_CLOSE_BUTTON = 'filter-modal-active-filter-modal-close-button',
  MOBILE_FILTER_MODAL = 'mobile-filter-modal',
  SORT_BY = 'sort-by',
  SEARCH_FILTER = 'search-filter',
  MOBILE_SORT_BY_MODAL = 'mobile-sort-by-modal',
  SEARCH_BAR_MOBILE_VIEW = 'search-bar-mobile-view',
  SEARCH_BAR_MOBILE_VIEW_TEXT_FIELD_SPECIALIST = 'search-bar-mobile-view-text-field-specialist',
  SEARCH_BAR_MOBILE_VIEW_TEXT_FIELD = 'search-bar-mobile-view-text-field',
  SEARCH_BAR_MOBILE_VIEW_TEXT_FIELD_LOCATION = 'search-bar-mobile-view-text-field-location',
  SEARCH_BAR_MOBILE_VIEW_SORT_BUTTON = 'search-bar-mobile-view-sort-button',
  SEARCH_BAR_MOBILE_VIEW_FILTER_BUTTON = 'search-bar-mobile-view-filter-button',
  FIND_PROVIDER = 'FindProvider',
  OTHER_SITES = 'OtherSites'
}

export const enum SORT_BY_OPTIONS {
  RELEVANCE = 'relevance',
  NEAREST = 'nearest',
  RATING = 'rating',
  MOST_ENDORSEMENTS = 'most-endorsements'
}

export const enum FILTER_OPTIONS {
  SPECIALTIES = 'specialties',
  DISTANCE = 'distance',
  TYPE_OF_CONSULTATION = 'type-of-consultation',
  GENDER = 'gender',
  LANGUAGE = 'language',
  INSURANCE = 'insurance'
}

export const enum MENU_ITEMS {
  MAIN_ABOUT = 'About',
  MAIN_TRUST = 'Trust',
  MAIN_FIND_PROVIDER = 'FindProvider',
  MAIN_OTHER_SITES = 'OtherSites',
  MAIN_BLOG = 'Blog',
  PROVIDER_FIND_SPECIALISTS = 'findSpecialist',
  PROVIDER_FIND_PRACTICE = 'findPractice',
  PROVIDER_DENTISTRY = 'dentistry',
  PROVIDER_HEALTH_CARE = 'healthCare',
  SITES_UK = 'UK',
  SITES_AE = 'AE',
  SITES_AT = 'AT',
  SITES_DE = 'DE',
  SITES_AU = 'AU',
  SITES_IE = 'IE'
}
